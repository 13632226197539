
import { defineAsyncComponent, defineComponent } from '@vue/runtime-core';
import { mapGetters } from "vuex";
import correctDescription from "@/utils/correctEnd";
import calculation from "./calculation";
import { createCharts } from "./charts";
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { dynamicsObject, valueof } from '@/interfaces';
import { Instrument } from '@/interfaces/dto/instrument';
import { COURSES_ENUM, PERIODS_ENUM } from '@/utils/enums';
import { COURSES_TYPE } from '@/store/commonDatas';

const FirstQuestionnaireTemplate = defineAsyncComponent(() => import('./first/index.vue'));
const SecondQuestionnaireTemplate = defineAsyncComponent(() => import('./second/index.vue'));
const CommonQuestionnaireActions = defineAsyncComponent(() => import('./common/actions.vue'));
const CommonQuestionnaireTemplateInfo = defineAsyncComponent(() => import('./common/info.vue'));
const CommonQuestionnaireTemplateChart = defineAsyncComponent(() => import('./common/chart.vue'));
const CommonQuestionnaireTemplateComment = defineAsyncComponent(() => import('./common/comment.vue'));
const CommonQuestionnaireTemplatePortfolios = defineAsyncComponent(() => import('./common/portfolios.vue'));

export default defineComponent({
  name: "PreviewMain",
  data() {
    return {
      targets: [] as Array<dynamicsObject>,
      periods: {
        NOT_PERIOD: "без пополнений",
        MONTHLY: "мес.",
        QUARTERLY: "кварт.",
        SEMIANNUALLY: "раз в полгода",
        ANNUALLY: "год",
      },
      student: {},
      expert: {},
      commonComment: "",
      ready: false,
      loading: true,
      avatar: "",
      pending: false,
      pendingStudent: false,
      pendingOnePage: false,
      questionnaire_id: this.$attrs._id,
      course: "" as valueof<typeof COURSES_ENUM>,
      mixedAssets: [],
      combine: false,
    };
  },
  computed: {
    ...mapGetters(["preview", "currencies"]),
  },
  created() {
    this.getMixedAssets();
  },
  mounted() {
    if (!this.questionnaire_id) return this.$router.push("/");
    this.jq("#app > main > header").remove();
    this.getQuestionnaire();
    this.emitter.on('sendQuestionnaire', this.sendQuestionnaireEmitter);
  },
  unmounted() {
    this.emitter.off('sendQuestionnaire', this.sendQuestionnaireEmitter);
  },
  methods: {
    async getMixedAssets() {
      const result = await this.API.common.getMixeds();
      this.mixedAssets = result.data;
    },
    async getQuestionnaire() {
      const result = await this.API.questionnaire.getQuestionnaire(this.questionnaire_id);
      setTimeout(() => {
        this.combine = !!result.data.content_COMBINE_EXPERT;
        this.course = result.data.course.type;
        this.transformData(
          result.data.content_EXPERT,
          result.data.expert
        );
        setTimeout(() => {
          createCharts(
            this.targets,
            this.preview[this.course === 'one' ? 'firstQuestionnaireColors' : 'secondQuestionnaireColors']
          );
          if (this.$router.currentRoute.value.query.collection === 'true') {
            setTimeout(() => {
              this.loading = false;
            }, 20000);
          } else {
            this.loading = false;
          }
        }, 200);
      }, 100);
    },
    transformData(questionnaire: Questionnaire.Dto, expert: dynamicsObject) {
      this.student = questionnaire.student.data.module.data;
      this.expert = expert;
      this.commonComment = questionnaire.comment.data.module.data.comment;
  
      questionnaire.targets.data.forEach((target) => {
        const term = target.type.sections[1].modules[0].data;
        const period = target.conclusion.sections[0].modules[0].data.period_id;
        const targetCurrency = target.main.data.currency_id;
        const targetCurrencySign = target.main.data.currency_sign;

        const current_term = calculation.currentTerm(term);
        const income = this.getCorrectCurrency(target.type.sections[0].modules, targetCurrency);
        const resourses = this.getCorrectCurrency(target.type.sections[4].modules, targetCurrency);

        const { profitability, inflation } = target.main.data;
        const correctProfitability = profitability / 100;
        const correctInflation = inflation / 100;

        let FV = calculation.FV(
          income,
          correctInflation,
          current_term
        );

        let capital = (FV * 12) / correctProfitability;
        if (target.type.id === 2) FV = capital;

        if (this.combine) {
          FV = Number(target.type.sections[3].modules[0].data.fv.replace(` ${target.main.data.currency_sign}`, ''));
        }

        const correctTarget = {
          id: target.id,
          number: target.id,
          name: target.main.data.name,
          mainCurrency: targetCurrency,
          type: target.type.id,
          income,
          resourses,
          term: `${term.term} ${correctDescription(term)}`,
          riskPortfolio: this.getCorrectPortfolioTag(
            target.type.sections[5].modules[0].data.portfolio
          ),
          conclusion: {
            amount: 0,
            period: this.getCorrectPeriod(period),
            replenishment: this.getCorrectCurrency(
              target.conclusion.sections[1].modules,
              targetCurrency
            ),
            comment: target.conclusion.sections[3].modules[0].data.comment,
          },
          chart: [] as Array<dynamicsObject>,
          portfolios: this.getCurrentPortfolios(target, FV),
          status: target.status,
          capital: 0,
          passive: 0,
          percent: 0,
        };

        let FVS = calculation.FV(
          income,
          correctInflation,
          current_term
        );
        if (this.combine) {
          FVS = Number(target.type.sections[3].modules[0].data.fv.replace(` ${target.main.data.currency_sign}`, ''));
        }
        const FV1 = calculation.FV1(
          resourses,
          correctProfitability,
          current_term
        );

        const percent = (FV1 / FV) * 100;
        const FV2 = FV - FV1;
        const R = FV2 / ((Math.pow(1 + correctProfitability, current_term) - 1) / correctProfitability);
        const currentPeriod = calculation.currentPeriod(period);
        const currentMonths = calculation.currentMonths(period);
        const currentR = R / currentPeriod < 0 ? 0 : Number((R / currentPeriod).toFixed(1));

        correctTarget.capital = Math.ceil10(capital, -1);
        correctTarget.passive = Math.ceil10(FVS, -1);
        correctTarget.percent = percent > 100 ? 100 : Math.ceil10(percent, -1);
        correctTarget.conclusion.amount = FV2 < 0 ? 0 : Math.ceil10(FV2, -1);

        correctTarget.chart = calculation.chartFill(
          target.type.sections[4].modules,
          current_term,
          currentPeriod,
          currentMonths,
          currentR,
          correctProfitability,
          correctTarget,
          targetCurrency,
          targetCurrencySign,
          this.getCorrectCurrency,
        );

        this.targets = [...this.targets, correctTarget];
      });
      this.ready = true;
    },
    getCurrentPortfolios(target: Questionnaire.QTarget, FV: number) {
      let portfolios = [] as Array<dynamicsObject>;
      let index = 0;
      const keys = Object.typedKeys(target.portfolios);
      for (const key of keys) {
        if (Object.hasOwnProperty.call(target.portfolios, key)) {
          const portfolio = target.portfolios[key];
          const coreInstruments = portfolio.sections[1].modules.filter(m => m.data.name && m.data.price).map(m => m.data);
          const tacticInstruments = key !== 'expert' ? portfolio.sections[2].modules.filter(m => m.data.name && m.data.price).map(m => m.data) : [];

          portfolios = [
            ...portfolios,
            {
              id: key,
              ct_percents: {
                "Ядро": portfolio.sections[0].modules[0].data.core,
                "Тактическая часть":
                  portfolio.sections[0].modules[0].data.tactic,
              },
              mainAmount: key !== 'expert' ? this.getCorrectAmountCurrency([...coreInstruments, ...tacticInstruments], target.main.data.currency_id, this.course) : FV.toFixed(2),
              core: {
                selected: !!coreInstruments.find(i => i.name && i.price),
                instruments: coreInstruments,
                class_percents: this.getCorrectStructurePercents(
                  key,
                  false,
                  coreInstruments,
                  "class_" + this.course
                ),
                country_percents: this.getCorrectStructurePercents(
                  key,
                  false,
                  coreInstruments,
                  "country_" + this.course
                ),
                currency_percents: this.getCorrectStructurePercents(
                  key,
                  false,
                  coreInstruments,
                  `base_currency_${this.course}_id`
                ),
                section_percents: this.getCorrectStructurePercents(
                  key,
                  false,
                  coreInstruments,
                  "section_" + this.course
                ),
                conserv_percents: this.getCorrectStructurePercents(
                  key,
                  false,
                  coreInstruments,
                  "instrument_type_" + this.course
                ),
                stock_risk_percents: this.getCorrectStructurePercents(key, false, coreInstruments, "instrument_type_" + this.course, "instrument_type_" + this.course, ["Акции", "Отраслевые ETF и БПИФ", "ETF и БПИФ на акции широкого рынка (не отраслевые)"]),
                bond_period_percents: this.getCorrectStructurePercents(key, false, coreInstruments, "matdate"),
                stock_percents: this.getCorrectStructurePercents(
                  key,
                  false,
                  coreInstruments,
                  "section_" + this.course,
                  "instrument_type_" + this.course,
                  ["Акции", "Отраслевые ETF и БПИФ"]
                ),
                index,
              },
              tactic: {
                selected: portfolio.sections[2]
                  ? portfolio.sections[2].selected
                  : false,
                instruments: tacticInstruments,
              },
              comments: {
                common: this.getCorrectComment(portfolio, "conclusion-comment"),
                stock: this.getCorrectComment(portfolio, "stock-comment"),
                bond: this.getCorrectComment(portfolio, "bond-comment"),
                alternative: this.getCorrectComment(
                  portfolio,
                  "alternative-comment"
                ),
                tactic: this.getCorrectComment(portfolio, "tactic-comment"),
              },
              // comment: portfolio.sections.find(s => s.default === 'conclusion-comment') ? portfolio.sections.find(s => s.default === 'conclusion-comment').modules[0].data.comment : ''
            },
          ];
          index++;
        }
      }
      return portfolios;
    },
    getCurrentInstruments(instruments: Array<Instrument.Dto>, type: string, filter?: string, filterElements?: Array<string>) {
      if (filter && filterElements) {
        if (type.includes("section_") || type.includes('instrument_type_'))
          return instruments.filter((i) => i[`class_${this.course}_id`] === "stock" && i["section_" + this.course] && filterElements.includes(i[filter]));
      } else {
        if (type === 'matdate')
          return instruments.filter((i) => i[`class_${this.course}_id`] === "bond");
        if (type.includes("section_"))
          return instruments.filter((i) => i[`class_${this.course}_id`] === "stock" && i["section_" + this.course]);
        if (type.includes("instrument_type_"))
          return instruments.filter((i) => i[`class_${this.course}_id`] === "bond" && i["instrument_type_" + this.course]);
      }
      return instruments;
    },
    getCorrectComment(portfolio: Questionnaire.QTargetPortfolio, name: string) {
      const p = portfolio.sections.find((s) => s.default === name);
      if (!p) return '';
      return this.parseLinks(p.modules[0].data.comment);
    },
    parseLinks(text: string) {
      return `<div>${text
        .replace(/\b(([\w-]+:\/\/?|www[.])[^\s()<>]+(?:\([\w\d]+\)|([^[:punct:]\s]|)))/g, '<a style="color: rgb(0, 89, 255);" href="$1">$1</a>')
        .replace(/([*].+?[*])/g, "<strong>$1</strong>")
        .replace(/\*/g, "")}</div>`;
    },
    getCorrectStructurePercents(pid: string, need: boolean, instruments: Array<Instrument.Dto>, type: string, filter?: string, filterElements?: Array<string>) {
      const result: dynamicsObject = {};
      const copy = this.getCurrentInstruments(instruments, type, filter, filterElements);
      
      copy.forEach((instrument: Instrument.Dto) => {
        const correct_type = instrument[type] ? instrument[type].trim() : instrument[type];
        if (!result[correct_type] && type !== 'matdate') result[correct_type] = 0;
        if (pid === 'expert' && need) {
          result[correct_type] += Number(instrument.percent);
        } else {
          let lot = this.course === COURSES_ENUM.TWO && instrument.lot ? instrument.lot : 1;
          const amount = instrument.price * instrument.number_papers * lot;

          const currentAmount =
            this.course === COURSES_ENUM.ONE ?
              Number(this.getUSDCurrency(amount, instrument[`currency_${this.course}_id`])) :
              Number(this.getRUBCurrency(amount, instrument[`currency_${this.course}_id`]));

          if ((type.includes('class_') || type.includes('section_')) && instrument[`instrument_type_${this.course}`] === "Фонды смешанных активов") {
            const mixedAsset: dynamicsObject | undefined = this.mixedAssets.find(
              (asset: dynamicsObject) => asset.name.toLowerCase().trim() === instrument.name.toLowerCase().trim()
            );
            if (mixedAsset) {
              const classes = COURSES_TYPE;
              if (type.includes('section_')) {
                result[correct_type] +=
                  currentAmount * ((mixedAsset as dynamicsObject).stock / 100);
              }
              if (type.includes('class_')) {
                classes.forEach((classAsset) => {
                  if (!result[classAsset[this.course]]) result[classAsset[this.course]] = 0;
                  result[classAsset[this.course]] += currentAmount * (mixedAsset[classAsset.id] / 100);
                });
              }
            }
          } else if (type === 'matdate') {
            const date = new Date(instrument.matdate);
            if (+date) {
              const correctDate = new Date();
              correctDate.setDate(correctDate.getDate() + 1460);
              if (correctDate <= date) {
                if (!result['Долгосрочные']) result['Долгосрочные'] = 0;
                result['Долгосрочные'] += currentAmount;
              } else {
                if (!result['Краткосрочные']) result['Краткосрочные'] = 0;
                result['Краткосрочные'] += currentAmount;
              }
            }
          } else {
            result[correct_type] += currentAmount;
          }
        }
        
      });
      if (Object.values(result).length > 0 && pid === 'expert' && need) {
        for (const key in result) {
          result[key] = Math.ceil10(result[key], -1);
        }
      }
      if (Object.values(result).length > 0 && !(pid === 'expert' && need)) {
        const total = Object.values(result).reduce((t, n) => t + n);
        for (const key in result) {
          if (Object.hasOwnProperty.call(result, key)) {
            result[key] = ((result[key] / total) * 100).toFixed(1);
          }
        }
      }
      if (result && result['Акции'] && type === filter && type === `instrument_type_${this.course}`) {
        Object.defineProperty(result, 'Акции отдельных компаний',
          Object.getOwnPropertyDescriptor(result, 'Акции') || '');
        delete result['Акции'];
      }
      return result;
    },
    getCorrectCurrency(datas: Array<Instrument.Module>, currency: string) {
      const rates = this.currencies;
      return Math.ceil10(datas
        .reduce((acc, data) => acc + (data.data.amount / rates[data.data.currency_id]) * rates[currency], 0), -1);
    },
    getCorrectAmountCurrency(instruments: Array<Instrument.Dto>, currency: string, course: valueof<typeof COURSES_ENUM>) {
      const rates = this.currencies;
      instruments = instruments.filter(i => i.name);
      return instruments.reduce((acc, instrument) => acc + (instrument.number_papers * ((course === 'two' && instrument.lot) ? instrument.lot : 1) * instrument.price / rates[instrument[`currency_${course}_id`]] * rates[currency]), 0).toFixed(0);
    },
    getOtherCurrency(amount: number, currency: string, baseCurrency: string) {
      const rates = this.currencies;
      return Math.ceil10((amount / rates[currency]) * rates[baseCurrency], -1);
    },
    getUSDCurrency(amount: number, currency: string) {
      const rates = this.currencies;
      return Math.ceil10(amount / rates[currency], -1);
    },
    getRUBCurrency(amount: number, currency: string) {
      const rates = this.currencies;
      return Math.ceil10((amount / rates[currency]) * rates["RUB"], -1);
    },
    division(last: boolean) {
      const all = document.querySelectorAll("*");
      all.forEach((element) => {
        const pelement = this.jq(element).closest(!last ? ".second1" : ".second2") || [];
        const parent = !!(pelement.length > 0);
        if (parent) {
          this.jq(element).addClass("eew");
        }
      });
      const hoffset = this.jq(!last ? ".second1" : ".second2").offset() || { top: 0 };
      const height = hoffset.top;
      this.jq(`${last ? ".second2" : ".second1"} .eew`)
        .toArray()
        .forEach((element) => {
          const offset = this.jq(element).offset() || { top: height };
          if (last) {
            if (
              offset.top - height < 7500 &&
              (this.jq(element).height() || 0) < 2000
            ) {
              this.jq(element).addClass("hiddenEEW");
            }
          } else {
            if (
              offset.top - height > 7500 &&
              (this.jq(element).height() || 0) < 2000
            ) {
              this.jq(element).addClass("hiddenEEW");
            }
          }
        });
      this.jq(`${last ? ".second2" : ".second1"} .hiddenEEW`).remove();
    },
    sendQuestionnaireEmitter() {
      this.createStudentFile();
    },
    async createStudentFile() {
      this.pendingStudent = true;
      await this.API.questionnaire.createStudentFile(this.questionnaire_id);
      this.pendingStudent = false;
    },
    async createOnePage() {
      this.pendingOnePage = true;
      const result = await this.API.questionnaire.createPDFOnePage(this.questionnaire_id)
      const a = document.createElement("a");
      a.href = result.data.src;
      a.download = result.data.name;
      a.click();
      this.pendingOnePage = false;
    },
    async create() {
      this.pending = true;
      const result = await this.API.questionnaire.createPDF(this.questionnaire_id);
      const a = document.createElement("a");
      a.href = result.data.src;
      a.download = result.data.name;
      a.click();
      this.pending = false;
    },
    getCorrectPeriod(period: keyof typeof PERIODS_ENUM) {
      return this.periods[period];
    },
    getCorrectPortfolioTag(portfolio: string) {
      const risk =
        this.course === "one"
          ? this.preview.firstQuestionnaireRisks.find((p: dynamicsObject) => p.name === portfolio)
          : this.preview.secondQuestionnaireRisks.find((p: dynamicsObject) => p.name === portfolio);
      risk.icon = risk.src;
      return risk;
    },
  },
  components: {
    FirstQuestionnaireTemplate,
    SecondQuestionnaireTemplate,
    CommonQuestionnaireActions,
    CommonQuestionnaireTemplateInfo,
    CommonQuestionnaireTemplateChart,
    CommonQuestionnaireTemplateComment,
    CommonQuestionnaireTemplatePortfolios,
  },
});
