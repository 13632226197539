import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e2c9c1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = {
  key: 1,
  id: "element-to-print2",
  style: {"position":"relative"},
  class: "pdf other second1"
}
const _hoisted_3 = {
  key: 2,
  id: "element-to-print3",
  class: "pdf other"
}
const _hoisted_4 = {
  key: 3,
  class: "pdf other",
  id: "element-to-print4"
}
const _hoisted_5 = { class: "page" }
const _hoisted_6 = {
  key: 4,
  class: "pdf other",
  id: "element-to-print5"
}
const _hoisted_7 = {
  key: 5,
  class: "pdf other",
  id: "element-to-print6"
}
const _hoisted_8 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonQuestionnaireActions = _resolveComponent("CommonQuestionnaireActions")!
  const _component_FirstQuestionnaireTemplate = _resolveComponent("FirstQuestionnaireTemplate")!
  const _component_SecondQuestionnaireTemplate = _resolveComponent("SecondQuestionnaireTemplate")!
  const _component_common_questionnaire_template_portfolios = _resolveComponent("common-questionnaire-template-portfolios")!
  const _component_common_questionnaire_template_comment = _resolveComponent("common-questionnaire-template-comment")!
  const _component_common_questionnaire_template_chart = _resolveComponent("common-questionnaire-template-chart")!
  const _component_common_questionnaire_template_info = _resolveComponent("common-questionnaire-template-info")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_CommonQuestionnaireActions, {
      pending: _ctx.pending,
      questionnaire_id: _ctx.questionnaire_id,
      pendingStudent: _ctx.pendingStudent,
      pendingOnePage: _ctx.pendingOnePage,
      loading: _ctx.loading,
      onCreate: _ctx.create,
      onCreateOnePage: _ctx.createOnePage
    }, null, 8, ["pending", "questionnaire_id", "pendingStudent", "pendingOnePage", "loading", "onCreate", "onCreateOnePage"]),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["pdf", _ctx.course]),
          id: "element-to-print1"
        }, [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.course === 'one')
              ? (_openBlock(), _createBlock(_component_FirstQuestionnaireTemplate, {
                  key: 0,
                  expert: _ctx.expert,
                  student: _ctx.student,
                  targets: _ctx.targets
                }, null, 8, ["expert", "student", "targets"]))
              : _createCommentVNode("", true),
            (_ctx.course === 'two')
              ? (_openBlock(), _createBlock(_component_SecondQuestionnaireTemplate, {
                  key: 1,
                  expert: _ctx.expert,
                  student: _ctx.student,
                  targets: _ctx.targets
                }, null, 8, ["expert", "student", "targets"]))
              : _createCommentVNode("", true)
          ])
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.ready && _ctx.targets[0])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_common_questionnaire_template_portfolios, {
            course: _ctx.course,
            target: _ctx.targets[0]
          }, null, 8, ["course", "target"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ready && _ctx.targets[1])
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_common_questionnaire_template_portfolios, {
            course: _ctx.course,
            target: _ctx.targets[1]
          }, null, 8, ["course", "target"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.targets[2])
            ? (_openBlock(), _createBlock(_component_common_questionnaire_template_portfolios, {
                key: 0,
                course: _ctx.course,
                target: _ctx.targets[2]
              }, null, 8, ["course", "target"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_common_questionnaire_template_comment, {
              course: _ctx.course,
              commonComment: _ctx.commonComment
            }, null, 8, ["course", "commonComment"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targets, (target) => {
            return (_openBlock(), _createBlock(_component_common_questionnaire_template_chart, {
              course: _ctx.course,
              chart: target.chart,
              number: target.number,
              key: target.id
            }, null, 8, ["course", "chart", "number"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("main", {
              class: _normalizeClass(_ctx.course)
            }, [
              _createVNode(_component_common_questionnaire_template_info, {
                course: _ctx.course,
                content: 
            _ctx.course === 'one'
              ? _ctx.preview.firstQuestionnaireInfo
              : _ctx.preview.secondQuestionnaireInfo
          
              }, null, 8, ["course", "content"])
            ], 2)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}